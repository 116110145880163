<template>
  <div v-if="selected_entity2" class="px-50">
    <div class="mt-1 d-inline-flex w-100 justify-content-between">
      <h4>
        Attributes
      </h4>
      <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      size="sm"
      variant="outline-success"
      @click="$bvModal.show('add-attribute-modal')"
    >
      <feather-icon icon="PlusIcon" class="text-success" />
      Add Attribute
    </b-button>
    </div>
    <div v-if="selected_entity2.attributes.length === 0">
      <p class="text-muted">
        There are no attributes for this element.
      </p>
    </div>
    <div v-else>
      <b-table id="prop_table" class="mt-50" striped hover :items="selected_entity2.attributes" :fields="fields">
        <template v-slot:cell(name)="data">
          <span class="text-primary"><b>{{ data.item.name }}</b></span>: {{ data.item.type }}
          <span v-if="data.item.unit && data.item.unit !== ''"> ({{ data.item.unit }})</span>
          <span v-if="data.item.value && data.item.value !== ''">
              = {{ data.item.value }}
          </span><br>
        </template>
        <template v-slot:cell(details)="data">
          <div v-if="(data.item.min && data.item.min !== '') || (data.item.max && data.item.max !== '') || (data.item.required && data.item.required !== 'false') || (data.item.unique && data.item.unique !== 'false')">
            <div v-if="(data.item.required && data.item.required !== 'false') || (data.item.unique && data.item.unique !== 'false')">
              <span v-if="data.item.required && data.item.required !== 'false'">Required</span> <span v-if="data.item.unique && data.item.unique !== 'false'">Unique</span><br>
            </div>
            <div v-if="(data.item.min && data.item.min !== '') || (data.item.max && data.item.max !== '')">
              <span v-if="data.item.min && data.item.min !== ''">Min: {{ data.item.min }}</span> <span v-if="data.item.max && data.item.max !== ''">Max: {{ data.item.max }}</span><br>
            </div>
          </div>
          <div v-else>
            No Constraints
          </div>
          <div v-if="data.item.default && data.item.default !== ''">
            Value (Default): {{ data.item.default }}
          </div>
          <div v-if="data.item.value && data.item.value !== ''">
              <b>Value (Actual):</b> {{ data.item.value }}
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button-group>
            <b-button title="Edit Attribute" variant="flat-success" size="sm" class="p-50">
              <feather-icon icon="EditIcon" @click="editAttribute(data.item)" />
            </b-button>
            <b-button title="Delete Attribute" variant="flat-danger" size="sm" class="p-50">
              <feather-icon icon="TrashIcon" @click="deleteAttribute(data.item)" />
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    </div>

    <hr class="mt-3">

    <!-- PROPERTIES -->
    <div class="d-inline-flex w-100 justify-content-between">
      <h4>
      Properties
    </h4>
      <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      size="sm"
      variant="outline-success"
      @click="$bvModal.show('add-property-modal')"
    >
      <feather-icon icon="PlusIcon" class="text-success" />
      Add Property
    </b-button>
    </div>
    <div v-if="selected_entity2.properties.length === 0">
      <p class="text-muted">
        There are no properties for this element.
      </p>
    </div>
    <div v-else>
      <b-table id="prop_table" class="mt-50" striped hover :items="selected_entity2.properties" :fields="p_fields">
        <template v-slot:cell(name)="data">
          <span class="text-primary"><b>{{ data.item.name }}</b></span>: {{ data.item.type }}
          <span v-if="data.item.unit && data.item.unit !== ''"> ({{ data.item.unit }})</span>
          <span v-if="data.item.value && data.item.value !== ''">
              = {{ data.item.value }}
          </span><br>
        </template>
        <template v-slot:cell(details)="data">
          <div v-if="(data.item.min && data.item.min !== '') || (data.item.max && data.item.max !== '')">
            <span v-if="data.item.min && data.item.min !== ''">Min: {{ data.item.min }}</span> <span v-if="data.item.max && data.item.max !== ''">Max: {{ data.item.max }}</span>
          </div>
          <div v-else>
            No constraints
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button-group>
            <b-button title="Edit Property" variant="flat-success" size="sm" class="p-50">
              <feather-icon icon="EditIcon" @click="editProperty(data.item)" />
            </b-button>
            <b-button title="Delete Property" variant="flat-danger" size="sm" class="p-50">
              <feather-icon icon="TrashIcon" @click="deleteProperty(data.item)" />
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    </div>

    <AddAttribute />
    <EditAttribute />
    <DeleteAttribute />
    <AddProperty />
    <EditProperty />
    <DeleteProperty />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AddAttribute from '@/components/Domain/Modals/Properties/AttributeAdd.vue'
import EditAttribute from '@/components/Domain/Modals/Properties/AttributeEdit.vue'
import DeleteAttribute from '@/components/Domain/Modals/Properties/AttributeDelete.vue'
import AddProperty from '@/components/Domain/Modals/Properties/PropertyAdd.vue'
import EditProperty from '@/components/Domain/Modals/Properties/PropertyEdit.vue'
import DeleteProperty from '@/components/Domain/Modals/Properties/PropertyDelete.vue'
import Ripple from 'vue-ripple-directive'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    AddAttribute,
    EditAttribute,
    DeleteAttribute,
    AddProperty,
    EditProperty,
    DeleteProperty,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      context: { attributes: [] },
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'details',
          label: 'Constraints/Details',
        },
        {
          key: 'actions',
          label: 'Actions',
          thClass: 'text-center',
          thStyle: 'width: 2rem',
        },
      ],
      p_fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'details',
          label: 'Constraints',
        },
        {
          key: 'actions',
          label: 'Actions',
          thClass: 'text-center',
          thStyle: 'width: 2rem',
        },
      ],
      c_fields: [
        {
          key: 'name',
          label: 'Name / Tag',
          sortable: true,
        },
        {
          key: 'gist',
          label: 'Desc.',
        },
        {
          key: 'requirement',
          label: 'QA Requirement',
          thClass: 'text-center',
        },
        {
          key: 'actions',
          label: 'Actions',
          thClass: 'text-center',
          thStyle: 'width: 2rem',
        },
      ],
      attributes: [],
      properties: [],
      constraints: [],
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
    default_class() {
      return this.$store.state.constants.defaultSecurityClassification.id
    },
  },
  watch: {
    selected_entity2(newVal) {
      this.name = newVal.name
    },
    component_context(newVal) {
      newVal.attributes.forEach(element => {
        element.constraints = (() => (`${element.required ? 'Required' : ''}  ${element.unique ? 'Unique' : ''}`))()
        element.actions = element.id
        this.attributes.push(element)

        // eslint-disable-next-line no-nested-ternary
        console.debug(element.required ? 'required' : `${element.unique}` ? 'unique' : '')
      })
      newVal.properties.forEach(element => {
        element.actions = element.id
        this.properties.push(element)
      })
      newVal.constraints.forEach(element => {
        element.actions = element.id
        this.constraints.push(element)
      })
      this.context = newVal
      console.debug(newVal)
    },
  },
  methods: {
    editAttribute(val) {
      this.$store.dispatch('domainModel/selectAttribute', val)
        .then(() => {
          this.$bvModal.show('edit-attribute-modal')
        })
    },
    deleteAttribute(val) {
      this.$store.dispatch('domainModel/selectAttribute', val)
        .then(() => {
          this.$bvModal.show('delete-attribute-modal')
        })
    },
    editProperty(val) {
      this.$store.dispatch('domainModel/selectProperty', val)
        .then(() => {
          this.$bvModal.show('edit-property-modal')
        })
    },
    deleteProperty(val) {
      this.$store.dispatch('domainModel/selectProperty', val)
        .then(() => {
          this.$bvModal.show('delete-property-modal')
        })
    },
    addConstraint() {
      this.$store.dispatch('domainModel/selectConstraint', null)
        .then(() => {
          console.log('Properties::addConstraint: ')
          const selectedId = this.selected_entity2.context.details.id
          const payload = {
            model: this.$store.state.model.id,
            name: 'New Constraint',
            id: uuidv4(),
            requirements: [],
          }
          this.$http.post(`/api/v2/domain_model/add_constraint/${selectedId}`, payload).then(async result => {
            this.loading_status = false
            this.$bvModal.hide('add-constraint-modal')
            const pl = { ...result.data, ...payload, ...{ name: '' } }
            await this.$store.dispatch('domainModel/selectConstraint', pl)
            this.$bvModal.show('edit-constraint-modal')
          })
        })
    },
    editConstraint(val) {
      this.$store.dispatch('domainModel/selectConstraint', val)
        .then(() => {
          console.log('Properties::editConstraint: ', val)
          this.$bvModal.show('edit-constraint-modal')
        })
    },
    deleteConstraint(val) {
      this.$store.dispatch('domainModel/selectConstraint', val)
        .then(() => {
          this.$bvModal.show('delete-constraint-modal')
        })
    },
    genConstraintReqs(val) {
      console.debug('Generate requirements from constraint: ', val)
      this.$store.dispatch('domainModel/selectConstraint', val)
        .then(() => {
          this.$bvModal.show('generate-constraint-requirements-modal')
        })
    },
  },
}
</script>

<style scoped>
  #prop_table {
    font-size: 0.8rem;
  }
  #constraint_table {
    font-size: 0.8rem;
  }
  .constraint-table-scroll {
    width: 100%;
    max-height: 90vh;
    overflow-x: auto;
    overflow-y: auto;
  }
</style>
